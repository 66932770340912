import { ElementRef, Injectable } from "@angular/core";
import { LANGUAGE_DEFAULT } from "./locale";
import { getEcSubdomainByLanguage, getGlobalConfig } from "../config";
import { Subscription } from "rxjs";
import { Emitter } from "./emitters/emitter";
import { Logger } from "./logger/logger";
import { AnalyticsFacebook } from "./analytics/trackers/facebook/facebook.analytics";
import { compact, get, includes, isEmpty, isObject, isString, isUndefined, set, split } from "lodash-es";
import { DateFnsConfig } from "./date-fns-util";

declare var ECSDK_ACCOUNT_ID: any;
declare var MAJOR_VERSION: string;

@Injectable({providedIn: "root"})
export class GlobalSettingService {
    static EVENT_SETTINGS_INITIALIZE: string = "onSettingInitialize";
    static EVENT_SETTINGS_CHANGE: string = "onSettingChange";

    private logger = new Logger();
    private emitter = new Emitter();
    private globalSettings = {};
    private initialized = false;

    constructor() {
        this.globalSettings = getGlobalConfig();

        DateFnsConfig.setLanguage(this.getLanguage());
        this.logger.log("MAJOR RELEASE:", MAJOR_VERSION);
        this.logger.log("compiled global settings", this.globalSettings);
        this.publish(GlobalSettingService.EVENT_SETTINGS_INITIALIZE, this.globalSettings);
    }

    get(settingPath: string): any {
        return get(this.globalSettings, settingPath);
    }

    set(settingPath: string, value: any): any {
        return set(this.globalSettings, settingPath, value);
    }

    setRaw(settingPath: string, rawValue: any): any {
        let parsedValue = this.parseSettings(rawValue);
        return this.set(settingPath, parsedValue);
    }

    parseSettings(settings: any): any {
        if (isUndefined(settings) || isEmpty(settings)) {
            return {};
        }
        if (isString(settings)) {
            try {
                return JSON.parse(settings);
            } catch (e) {
                return {};
            }
        }
        if (isObject(settings)) {
            return settings;
        }

        return {};
    }

    setFromObject(settings: object, settingPath: string, globalSettingPath?: string) {
        let outPath = globalSettingPath || settingPath;
        let settingValue = get(settings, settingPath);
        if (!isUndefined(settingValue)) {
            this.set(outPath, settingValue);
        }
    }

    isCmsMode(): boolean {
        return this.get("cmsMode") == true;
    }

    isProductionMode(): boolean {
        return this.get("productionMode") == true;
    }

    getPublicPath(): string {
        return this.get("publicPath");
    }

    getLanguage(): string {
        return this.get("lang") || LANGUAGE_DEFAULT;
    }

    getLanguageDomain(language: string, useStaticConfig: boolean = true): string {
        let currentUrl;
        const newSubdomain = getEcSubdomainByLanguage(language);
        try {
            currentUrl = new URL(window.location.href);
        } catch (e) {
            return this.get("siteUrl.global").replace("[LANG]", newSubdomain);
        }

        let [subdomain, ...parts] = currentUrl.host.split(".");
        const EMERGE_DOMAIN = "emerge";
        if (subdomain.indexOf(EMERGE_DOMAIN) !== -1) {
            return `${newSubdomain}.${currentUrl.host}`;
        }
        if (useStaticConfig) {
            return this.get("siteUrl.global").replace("[LANG]", newSubdomain);
        }
        return `https://${newSubdomain}.${parts.join(".")}`;
    }

    getInitAccountId(): string {
        if (typeof ECSDK_ACCOUNT_ID !== "undefined") {
            return ECSDK_ACCOUNT_ID;
        }
        return "";
    }

    getSdkToken(): string {
        let token = (<any>window).ECSDK_TOKEN;
        if (typeof token !== "undefined") {
            return token;
        }
        return "";
    }

    getJwtToken(): string {
        let token = (<any>window).EC_JWT;
        if (typeof token !== "undefined") {
            return token;
        }
        return "";
    }

    getApplicationBuildDate(): string | undefined {
        return this.get("applicationBuildDate");
    }

    initializeFromElement(elementRef: ElementRef, shouldPublishSettingsChange: boolean = true): void {
        if (!this.initialized) {
            let settings = this.parseSettings(elementRef.nativeElement.getAttribute("settings"));
            if (settings) {
                this.setFromObject(settings, "isBot");
                this.setFromObject(settings, "isChina");
                this.setFromObject(settings, "cmsMode");
                this.setFromObject(settings, "useFullPath");
                this.setFromObject(settings, "studentAuthenticationMode");
                this.setFromObject(settings, "applicationBuildDate");
                this.setFromObject(settings, "isVietnamDomain");
            }

            let metrics = elementRef.nativeElement.getAttribute("user-metrics");
            if (metrics) {
                this.setRaw("userMetrics", metrics);
            }
            this.initialized = true;
            this.logger.log("GLOBAL SETTINGS", this.globalSettings);
            if (shouldPublishSettingsChange) {
                this.publish(GlobalSettingService.EVENT_SETTINGS_CHANGE, this.globalSettings);
            }
        }
    }

    getFacebookPixelId(geolocation?: string): string {
        const defaultPixelId = this.get("analytics.facebook.pixelId");
        if (isEmpty(geolocation) || !includes(AnalyticsFacebook.WHITE_LISTED_COUNTRIES, geolocation)) {
            return defaultPixelId;
        }
        return this.get(`analytics.facebook.geolocation.${geolocation}`) || defaultPixelId;
    }

    getTiktokPixelId(): string | undefined {
        if (!this.get("analytics.tiktok.enabled")) {
            return undefined;
        }
        return this.get("analytics.tiktok.pixelId");
    }

    subscribe(eventName: string, successFn: (data?) => void, errorFn?: (e?) => void): Subscription {
        return this.emitter.subscribe(eventName, successFn, errorFn);
    }

    publish(eventName: string, data?: any): void {
        this.emitter.publish(eventName, data);
    }

    getObservable(eventName: string) {
        return this.emitter.getObservable(eventName);
    }
}

export const parseBoolean = (elementRef: ElementRef, attribute: string, defaultValue?: boolean): boolean | undefined => {
    let value = elementRef.nativeElement.getAttribute(attribute);
    if (!value) {
        return defaultValue;
    }
    return value == "true";
};

export const parseString = (elementRef: ElementRef, attribute: string, defaultValue?: string): string => {
    let value = elementRef.nativeElement.getAttribute(attribute);
    return value || defaultValue || "";
};

export const parseNumber = (elementRef: ElementRef, attribute: string, defaultValue?: number): number => {
    let value = elementRef.nativeElement.getAttribute(attribute);
    return parseInt(value) || defaultValue || 0;
};

export const parseJson = <T>(elementRef: ElementRef, attribute: string, defaultValue?: T): T | undefined => {
    let value = elementRef.nativeElement.getAttribute(attribute);
    try {
        return JSON.parse(value) ?? defaultValue;
    } catch (e) {
        return defaultValue;
    }
};

export const parseCsv = (elementRef: ElementRef, attribute: string, defaultValue?: any[]): any[] | undefined => {
    let value = elementRef.nativeElement.getAttribute(attribute);
    return compact(split(value, ","));
};
