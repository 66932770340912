import { AnalyticsGoogle } from "./google/google.analytics";
import { AnalyticsFacebook } from "./facebook/facebook.analytics";
import { includes, isEmpty } from "lodash-es";
import { GoogleAds } from "./google/google.ads";
import { CriteoConversionType } from "./criterio/criteo.tracker";
import { AnalyticsTiktok } from "./tiktok/tiktok.analytics";

export const enum TrackerName {
    FB = "FB",
    GA = "GA",
    ADJUST = "ADJUST",
    NAVER = "NAVER",
    A8FLY = "A8FLY",
    YAHOO = "YAHOO",
    LINE = "LINE",
    CRITEO = "CRITEO",
    TIKTOK = "TIKTOK",
    CLARITY = "CLARITY",
    NA = "NA",
    ADLOCATE = "ADLOCATE",
    PERTO = "PERTO"
}

export const isTrackerRestrictedByGeolocation = (geolocationRestricted: string, geolocation?: string) => {
    return includes(geolocationRestricted, geolocation);
};

export const isTrackerInAllowedGeolocation = (enabled: boolean, geolocationAllowed: string, geolocation?: string) => {
    return enabled && (!isEmpty(geolocation) && includes(geolocationAllowed, geolocation));
};

type TrackParams = AnalyticsGoogle.TrackParams | AnalyticsFacebook.TrackParams | AnalyticsTiktok.TrackParams;

export interface Tracker {
    init(): void;

    log(message?: string, logMethod?: string): void;

    updateCustomDimensions?(customDimensionsUpdate: TrackerCustomDimensions): TrackerCustomDimensions;

    trackPageView(page: string | undefined): void;

    trackEvent(trackParams: TrackParams): void;

    trackConversion?(conversionParams?: TrackConversionParams): void;

    trackTiming?(timingCommandMethodFields: AnalyticsGoogle.TimingCommandMethodFields): void;
}

export interface TrackerCustomDimensions {
    accountId?: number;
    userType?: string;
    subscriberType?: string;
    partnerId?: number;
    arrivalId?: string;
    planName?: string;
    identityId?: string;
    appVersion?: string;
    appId?: string;
}

export interface TrackConversionParams {
    send_to?: string; // Google sendToId inside config file
    value?: number; // Conversion value. Purchase price should be sent
    currency?: string; // Sets the currency of the conversion value
    event_callback?: () => void; // A callback function which is triggered after an event is being fired.
    transaction_id?: number | string; // Transaction id for tracking the purchases
    conversionType?: GoogleAds.ConversionType;
    conversionKey?: CriteoConversionType;
    programId?: string; // a8
    orderNumber?: number;
    currencyCode?: string;
    itemCode?: number;
    totalOrderPrice?: number;
    accountId?: number;
}

export type TrackerCustomDimensionsList = {[key: string]: TrackerCustomDimensions[keyof TrackerCustomDimensions]};

export interface TrackerCustomInitializeParams {
    appVersion?: string;
    appId?: string;
    isPertoPluginEnabled?: boolean;
}
