import { inject } from "@angular/core";
import { IdentityService } from "../core/identity.service";
import { Router } from "@angular/router";
import { ROUTE_CORPORATE, ROUTE_TESTCENTRAL } from "../pwa-v2-landing-app/routes/routes";

export default function testCentralAuthGuard() {
    let identityService = inject(IdentityService);
    let router = inject(Router);

    if (identityService.hasIdentity()) {
        return true;
    }
    return router.parseUrl(`${ROUTE_TESTCENTRAL}/${ROUTE_CORPORATE}`);
}