import { CourseUnitProgress } from "./course-unit-progress";
import { Dialog } from "./dialog";
import { OrganizationReportPoint } from "./organization-report";
import { RecognizerResult } from "./speech/recognizer-result";
import { Tutor } from "./tutor";
import { SessionV4 } from "./tutor/session-v4";
import { SessionReport } from "./tutor/session-report";
import { CoooriStudentStatisticsReport } from "./bridge/cooori-report";

abstract class ClassReportAbstract {
    accountID: number;
    accountStatus: number;
    email: string;
    avatarURL: string;
    goLiveEligible: string;
    isCourseOnly: string;
    alias?: string;
    name: string;
    nativeLanguage?: string;
    paid?: boolean;
    planId?: number;
    unitProgress?: CourseUnitProgress[];
    accountId?: number;
    products?: ReportProduct[];
    coooriStatistic?: CoooriStudentStatisticsReport;
}

class ReportProduct {
    productId: number;
    expiration: number;
}

export class ClassReport extends ClassReportAbstract {
    reports: ClassReportPoint[];
}

export class ClassReportCourseUnitCount extends ClassReportAbstract {
    reports: ClassCourseUnitReportCount[];
}

class ClassCourseUnitReportCount {
    unitId: number;
    reportCounts: ClassReportPoint[];
}

export class ClassReportCourseUnitProgress extends ClassReportAbstract {
    reports: ClassCourseProgressReportPoint[];
}

export class ClassCourseProgressReportPoint {
    courseId: number;
    points: number;
    unitProgress: ClassCourseUnitProgressReportPoint[];
}

export class ClassCourseUnitProgressReportPoint {
    unitId: number;
    points: number;
    reportCounts: ClassReportPoint[];
}

export class ClassReportPoint {
    reportType: string;
    points?: number;
    scores?: ClassReportScore[];
    letterGrade?: string;
    courseCompletions?: ClassReportCourseProgress[];
}

export class ClassReportScore {
    label: string;
    score: number;
    color?: string;
    symbol?: string;
}

export class ClassReportCourseProgress {
    courseID: number;
    courseName: string;
    percentage: number;
}

export class ClassReportTabulated extends ClassReport {
    totalLines?: number;
    totalLearn?: number;
    letterGrade?: string;
    totalDiscussionRecordings?: number;
    totalIncorrect?: number;
    totalAttempts?: number;
    knownWords?: number;
    currentRank?: number;
    wordListPercentage?: number;
    wordListMasteryPercentage?: number;
    sentence?: number;
}

export class SessionV4WithData extends SessionV4 {
    tutor?: Tutor;
    levelReport?: SessionReport;
    dialogContent?: Dialog;
}

export class ClassReportWithSessions extends ClassReport {
    isSessionsLoading?: boolean = false;
    isSessionsFetchZero?: boolean = false;
    sessions: SessionV4WithData[];
}

export class ClassReportAverageWeeklyPeriod {
    periodStart: string;
    periodEnd: string;
    progress: number;
}

export class ClassReportAverageWeekly {
    average: number;
    periods: ClassReportAverageWeeklyPeriod[];
    studentInfo: ClassReport[];
}

export class ClassReportCms {
    classId: number;
    classReports: ClassReportCmsPoint[];
    goLiveGoal: number;
    learnGoal: number;
    linesGoal: number;
    theClassName: string;
    watchGoal: number;
}

export interface ClassReportCmsPoint {
    accountID: number;
    accountStatus: string;
    dateAdded: string;
    email: string;
    hasUsedMobile: boolean;
    isCourseOnly: boolean;
    name: string;
    nativeLanguage: string;
    reports: OrganizationReportPoint[];
    type: string;
}

export class ClassReportWithLoginSummary extends ClassReport {
    firstLogin?: string;
    lastLogin?: string;
    numVisits?: string;
}

export class ClassReportComprehensionQuiz {
    comprehensionQuizClassReports: ClassReportComprehensionQuizProgress[];
}

export class ClassReportComprehensionQuizProgress {
    accountID: number;
    correctAnswers?: number;
    correctAnswersAtFirstAttempt?: number;
    totalItems?: number;
}

export class ClassReportAverageGrade {
    accountId: number;
    letterGrade: string;
    linePoints: number;
    maxPoints: number;
}

export class ClassReportAverageGrades {
    averageGradeReports: ClassReportAverageGrade[];
    constructor(averageGradeReports) {
        this.averageGradeReports = averageGradeReports;
    }
}

export class ClassReportAccountSentence {
    accountSentenceId: number;
    correct?: boolean;
    score?: number;
    activityTypeId: number;
    poinstLine?: number;
    poinstMax?: number;
    xmlUrl?: string;
    attempted: number;
    letterGrade: string;
    sentence: string;
    xmlResult?: RecognizerResult;
}

export class ClassReportAccountSentences {
    accountSentenceProgressList: ClassReportAccountSentence[];
}

export type ClassReportPronunciationView = ClassReport & { worstWordPoints: number };

export const enum REPORT_TYPE {
    GOAL_COMPLETION = "goalCompletion",
    WATCH = "watch",
    LEARN = "learn",
    TOTAL_LEARN = "totalLearn",
    SPEAK = "speak",
    AVERAGE_SPEAK = "averageSpeak",
    LINES = "lines",
    TOTAL_LINES = "totalLines",
    GO_LIVE = "goLive",
    MISSED_LESSON = "missedLessons",
    TUTOR = "tutor",
    COURSE = "course",
    COURSEUNIT = "courseunit",
    SENTENCE = "sentence",
    TOTAL_SENTENCE = "totalSentence",
    DISCUSSION = "discussion",
    TOTAL_DISCUSSION_RECORDINGS = "totalDiscussionRecordings",
    WORDLIST_CURRENT_RANK = "currentRank",
    WORDLIST_PERCENTAGE = "wordListPercentage",
    WORST_WORDS_COUNT = "worstWordsLastNCount",
    TIME_ON_TASK = "timeOnTask",
    OPEN_RESPONSE_CHAT = "openResponseChat",
    TOTAL_OPEN_RESPONSE_CHAT = "totalOpenResponseChat",
    CHAT = "chat",
    TOTAL_CHAT = "totalChat",
    DIALOG_CHAT = "dialogChat",
    TOTAL_DIALOG_CHAT = "totalDialogChat",
    TOTAL_ALL_RECORDINGS = "totalAllRecordings",
    PRONUNCIATION = "pronunciation",
    VOCABULARY = "vocabulary",
    TEST = "test",
    TOTAL_CORRECT = "totalCorrect",
    TOTAL_INCORRECT = "totalIncorrect",
    TOTAL_ATTEMPTS = "totalAttempts",
    WORD_LIST_CURRENT_RANK = "currentRank",
    WORD_LIST_PERCENTAGE = "wordListPercentage",
    WORD_LIST_MASTERY_PERCENTAGE = "wordListMasteryPercentage",
    KNOWN_WORDS = "knownWords",
    LESSONS = "lessons",
}

export const isReportTypeGoalCompletion = (reportType: REPORT_TYPE): boolean => {
    return reportType === REPORT_TYPE.GOAL_COMPLETION;
};

export const isReportTypeWatch = (reportType: REPORT_TYPE): boolean => {
    return reportType === REPORT_TYPE.WATCH;
};

export const isReportTypeLearn = (reportType: REPORT_TYPE | string): boolean => {
    return reportType === REPORT_TYPE.LEARN;
};

export const isReportTypeLines = (reportType: REPORT_TYPE): boolean => {
    return reportType === REPORT_TYPE.LINES;
};

export const isReportTypeGoLive = (reportType: REPORT_TYPE): boolean => {
    return reportType === REPORT_TYPE.GO_LIVE;
};

export const isReportTypeChat = (reportType: REPORT_TYPE | string): boolean => {
    return reportType === REPORT_TYPE.CHAT;
};


export const enum REPORT_CURRICULUM_TYPE {
    WEEKLY = "weekly",
    MONTHLY = "monthly",
    CUSTOM = "total"
}

export const REPORT_FILTER_ALL = 0;
export const REPORT_FILTER_CLASS = 1;
export const REPORT_TYPES = [
    REPORT_TYPE.WATCH,
    REPORT_TYPE.LEARN,
    REPORT_TYPE.SPEAK,
    REPORT_TYPE.LINES,
    REPORT_TYPE.GO_LIVE,
    REPORT_TYPE.GOAL_COMPLETION,
    REPORT_TYPE.TOTAL_LINES,
    REPORT_TYPE.TOTAL_LEARN,
    REPORT_TYPE.AVERAGE_SPEAK,
    REPORT_TYPE.CHAT,
    REPORT_TYPE.TOTAL_CHAT,
    REPORT_TYPE.DIALOG_CHAT,
    REPORT_TYPE.TOTAL_DIALOG_CHAT
];
export const FILTER_ALL = 0;
export const FILTER_CLASS = 1;
export const SORT_ACTIVITY: number = 1;
export const SORT_ALPHA: number = 0;

export const LESSON_TYPE_GOLIVE = "goLive";
export const LESSON_TYPE_VIDEOLESSON = "videoLesson";
export const LESSON_TYPE_LEVELTEST = "levelTest";
export const LESSON_TYPE_LEVELUPTEST = "levelUpTest";
export const LESSON_TYPE_OPENTALK = "openTalk";
export const LESSON_TYPES = [
    LESSON_TYPE_GOLIVE,
    LESSON_TYPE_VIDEOLESSON,
    LESSON_TYPE_LEVELTEST,
    LESSON_TYPE_LEVELUPTEST,
    LESSON_TYPE_OPENTALK
];

export const LESSON_STATUS_ALL = "all";
export const LESSON_STATUS_COMPLETED = "completed";
export const LESSON_STATUS_MISSED = "missed";

export const CQ_REPORT_CORRECT_ANSWERS_FIRST_ATTEMPT = "cqCorrectAnswersAtFirstAttempt";
export const CQ_REPORT_TOTAL_ITEMS = "cqTotalItems";

export class ClassReportPronunciation extends ClassReport {
    scores: ClassReportPronunciationTile[];
}

export class ClassReportPronunciationTile {
    label: string;
    score: number;
    color: string;
    symbol: string;
}

export const MINUTES_PER_HOUR = 60;
const CLASSREPORT_TIMEONTASK_WATCH_MULTIPLIER = 0.1;
const CLASSREPORT_TIMEONTASK_LEARN_MULTIPLIER = 0.02;
const CLASSREPORT_TIMEONTASK_SPEAK_MULTIPLIER = 0.02;
const CLASSREPORT_TIMEONTASK_GOLIVE_MULTIPLIER = 0.5;
const CLASSREPORT_TIMEONTASK_CHAT_MULTIPLIER = 0.025;

export const computeTimeOnTaskMinutes = (reportStudent: ClassReport, withChatGoal: boolean = true): number => {
    const multiplyValues = (quantity?: number, multiplier?: number): number => {
        return (quantity ?? 0) * (multiplier ?? 1) * MINUTES_PER_HOUR;
    };

    return [
        { reportType: REPORT_TYPE.WATCH, multiplier: CLASSREPORT_TIMEONTASK_WATCH_MULTIPLIER },
        { reportType: REPORT_TYPE.LEARN, multiplier: CLASSREPORT_TIMEONTASK_LEARN_MULTIPLIER },
        { reportType: REPORT_TYPE.LINES, multiplier: CLASSREPORT_TIMEONTASK_SPEAK_MULTIPLIER },
        { reportType: REPORT_TYPE.GO_LIVE, multiplier: CLASSREPORT_TIMEONTASK_GOLIVE_MULTIPLIER },
        ...withChatGoal ? [{ reportType: REPORT_TYPE.CHAT, multiplier: CLASSREPORT_TIMEONTASK_CHAT_MULTIPLIER }] : []
    ].map((item: {reportType: string, multiplier: number}) => {
        const reports = reportStudent?.reports ?? [];
        const pointReport = reports.find((reportPoint) => {
            return reportPoint.reportType == item.reportType;
        });
        return multiplyValues(pointReport?.points ?? 0, item.multiplier);
    }).reduce((acc: number, item: number) => {
        return acc + item;
    }, 0);
};

export const formatTimeOnTaskDisplayMinutes = (points: number): string => {
    if (!points) {
        return "0 min";
    }
    const taskMinutes = points * MINUTES_PER_HOUR;
    return taskMinutes > MINUTES_PER_HOUR
        ? `${Math.floor(taskMinutes / MINUTES_PER_HOUR)} hr ${Math.floor(taskMinutes % MINUTES_PER_HOUR)} min`
        : `${Math.floor(taskMinutes)} min`;
};
