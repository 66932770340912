import { Injectable, NgZone } from "@angular/core";
import { GlobalSettingService } from "../global-setting.service";
import { Logger } from "../logger/logger";
import { AnalyticsFacebook } from "./trackers/facebook/facebook.analytics";
import {
    isTrackerInAllowedGeolocation,
    isTrackerRestrictedByGeolocation,
    TrackConversionParams,
    Tracker,
    TrackerCustomDimensions,
    TrackerCustomInitializeParams,
    TrackerName
} from "./trackers/tracker";
import { FacebookTracker } from "./trackers/facebook/facebook.tracker";
import { GoogleTracker } from "./trackers/google/google.tracker";
import { AnalyticsGoogle } from "./trackers/google/google.analytics";
import { LOG_TAG_ANALYTICS } from "./trackers/tracker.logger";
import { isArray, isEmpty, isFunction, isObject, isUndefined, join, keys, map, reduce } from "lodash-es";
import { GoogleAdsTracker } from "./trackers/google/google-ads.tracker";
import { GoogleAds } from "./trackers/google/google.ads";
import { NaverTracker } from "./trackers/naver/naver.tracker";
import { A8FlyTracker } from "./trackers/a8Fly/a8-fly.tracker";
import { YahooTracker } from "./trackers/yahoo/yahoo.tracker";
import { LineTracker } from "./trackers/line/line.tracker";
import { CriteoTracker } from "./trackers/criterio/criteo.tracker";
import { TiktokTracker } from "./trackers/tiktok/tiktok.tracker";
import { AnalyticsTiktok } from "./trackers/tiktok/tiktok.analytics";
import { ClarityTracker } from "./trackers/clarity/clarity.tracker";
import { AdlocateTracker } from "./trackers/adlocate/adlocate.tracker";
import { GoogleTagManagerTracker } from "./trackers/google/google-tag-manager.tracker";
import { PertoTracker } from "./trackers/perto/perto.tracker";

// INFO ==> Google Analytics and Google Ads require different scripts for tracking

@Injectable({providedIn: "root"})
export class AnalyticsService {
    private logger = new Logger();
    // Trackers (Google Analytics and Facebook)
    private trackers: { [trackerName: string]: Tracker } = {};
    private eventStore: { [eventStoreKey: string]: string } = {};
    // Google Ads Tracker
    private gaAdsTracker: GoogleAdsTracker;
    private geolocation: string;

    constructor(private zone: NgZone, private globalSettingService: GlobalSettingService) {}

    initializeTrackers(
        geolocation: string = "default",
        isDebugModeEnabled: boolean = true,
        additionalParams: TrackerCustomInitializeParams = {}
    ): void {
        if (this.isProductionMode() || isDebugModeEnabled) {
            this.setGeolocation(geolocation);
            this.initializeNaver();
            this.initializeA8();
            this.initializeYahoo();
            this.initializeLine();
            this.initializeCriteo();
            this.initializeTiktok();
            this.initializeClarity();
            this.initializeAdlocate();
            if (!this.globalSettingService.get("isChina")) {
                this.initializeFacebook();
                this.initializeGoogle();
            }
            if (additionalParams?.isPertoPluginEnabled) {
                this.initializePertoPlugin();
            }
        }
    }

    initializeFacebook(): void {
        const config = this.globalSettingService.get("analytics.facebook");
        if (isTrackerRestrictedByGeolocation(config?.geolocationRestriction, this.getGeolocation())) {
            return;
        }
        const facebookPixelId = this.globalSettingService.getFacebookPixelId(this.getGeolocation());
        if (!config.enabled || isUndefined(facebookPixelId)) {
            return this.logger.log(`${LOG_TAG_ANALYTICS} Facebook pixel is not found for this environment.`);
        }
        try {
            this.trackers[TrackerName.FB] = new FacebookTracker(facebookPixelId, this.isProductionMode());
            this.trackers[TrackerName.FB]?.init();
        } catch (e) {
            this.logger.log(`${LOG_TAG_ANALYTICS} Facebook pixel initialization failed`);
        }
    }

    initializeTiktok(): void {
        const config = this.globalSettingService.get("analytics.tiktok");
        if (isTrackerRestrictedByGeolocation(config?.geolocationRestriction, this.getGeolocation())) {
            return;
        }
        const tiktokPixelId = this.globalSettingService.getTiktokPixelId();
        if (!config.enabled || isUndefined(tiktokPixelId)){
            return this.logger.log(`${LOG_TAG_ANALYTICS} Tiktok pixel is currently disabled for this environment`);
        }
        try {
            this.trackers[TrackerName.TIKTOK] = new TiktokTracker(tiktokPixelId, this.isProductionMode());
            this.trackers[TrackerName.TIKTOK]?.init();
        } catch (e) {
            this.logger.log(`${LOG_TAG_ANALYTICS} Tiktok pixel initialization failed`);
        }
    }

    initializeGoogle(): void {
        const config = this.globalSettingService.get("analytics.google");
        if (isTrackerRestrictedByGeolocation(config?.geolocationRestriction, this.getGeolocation())) {
            return;
        }

        if (!config.enabled) {
            return this.logger.log(`${LOG_TAG_ANALYTICS} Google analytics code is not found for this environment.`);
        }
        this.initializeGoogleAnalytics();
        this.initializeGoogleTagManager();
        this.initializeGoogleAds();
    }

    private initializeGoogleAnalytics(): void {
        const userMetrics = this.globalSettingService.get("userMetrics");
        const googleCode = this.globalSettingService.get("analytics.google.code");
        const googleAnalyticsError = `${LOG_TAG_ANALYTICS} Google analytics initialization failed`;
        // Google Analytics
        if (!isEmpty(googleCode)) {
            try {
                this.trackers[TrackerName.GA] = new GoogleTracker(googleCode, true, userMetrics);
                this.trackers[TrackerName.GA]?.init();
            } catch (e) {
                this.logger.log(googleAnalyticsError);
            }
        } else {
            this.logger.log(googleAnalyticsError);
        }
    }

    private initializeGoogleTagManager(): void {
        const gtmId = this.globalSettingService.get("analytics.google.gtmId");
        const googleAnalyticsError = `${LOG_TAG_ANALYTICS} Google tag manager initialization failed`;
        // Google Analytics
        if (!isEmpty(gtmId)) {
            try {
                const gtmTracker = new GoogleTagManagerTracker(gtmId, true, undefined, undefined);
                gtmTracker.init();
            } catch (e) {
                this.logger.log(googleAnalyticsError);
            }
        } else {
            this.logger.log(googleAnalyticsError);
        }
    }

    private initializeGoogleAds(): void {
        // Google Ads
        const googleAdsTag = this.globalSettingService.get("analytics.google.adsId");
        const googleAdsError = `${LOG_TAG_ANALYTICS} Google ads initialization failed`;
        if (!isEmpty(googleAdsTag)) {
            const sendToIdRegister = this.globalSettingService.get("analytics.google.adsSendToIdRegister");
            const sendToIdPurchase = this.globalSettingService.get("analytics.google.adsSendToIdPurchase");
            const sendToIdFreeLesson = this.globalSettingService.get("analytics.google.adsSendToIdFreeLesson");
            const sendToIdRegisterKo = this.globalSettingService.get("analytics.google.adsSendToIdRegisterKo");
            const sendToIdPurchaseKo = this.globalSettingService.get("analytics.google.adsSendToIdPurchaseKo");
            const adsId = this.globalSettingService.get("analytics.google.adsId");
            const sendToIds = {
                [GoogleAds.ConversionType.REGISTER]: `${adsId}/${sendToIdRegister}`,
                [GoogleAds.ConversionType.PURCHASE]: `${adsId}/${sendToIdPurchase}`,
                [GoogleAds.ConversionType.FREE_LESSON]: `${adsId}/${sendToIdFreeLesson}`,
                [GoogleAds.ConversionType.REGISTER_KO]: `${adsId}/${sendToIdRegisterKo}`,
                [GoogleAds.ConversionType.PURCHASE_KO]: `${adsId}/${sendToIdPurchaseKo}`
            };
            this.gaAdsTracker = new GoogleAdsTracker(
                googleAdsTag,
                sendToIds,
                this.isProductionMode()
            );
            this.gaAdsTracker.init();
        } else {
            this.logger.log(googleAdsError);
        }
    }

    private initializeNaver(): void {
        const config = this.globalSettingService.get("analytics.naver");
        if (!isTrackerInAllowedGeolocation(config.enabled, config?.geolocationAllowed, this.getGeolocation())) {
            return this.logger.log(`${TrackerName.NAVER} is disabled`);
        }

        try {
            this.trackers[TrackerName.NAVER] = new NaverTracker(config, this.isProductionMode());
            this.trackers[TrackerName.NAVER]?.init();
        } catch (e) {
            this.logger.log(`${TrackerName.NAVER} initialization failed`);
        }
    }

    private initializeA8(): void {
        const config = this.globalSettingService.get("analytics.a8Fly");
        if (!isTrackerInAllowedGeolocation(config.enabled, config?.geolocationAllowed, this.getGeolocation())) {
            return this.logger.log(`${TrackerName.A8FLY}  is disabled`);
        }

        try {
            this.trackers[TrackerName.A8FLY] = new A8FlyTracker(config, this.isProductionMode());
            this.trackers[TrackerName.A8FLY]?.init();
        } catch (e) {
            this.logger.log(`${TrackerName.A8FLY} initialization failed`);
        }
    }

    private initializeAdlocate(): void {
        const config = this.globalSettingService.get("analytics.adlocate");
        if (!isTrackerInAllowedGeolocation(config.enabled, config?.geolocationAllowed, this.getGeolocation())) {
            return this.logger.log(`${TrackerName.ADLOCATE}  is disabled`);
        }

        try {
            this.trackers[TrackerName.ADLOCATE] = new AdlocateTracker(config, this.isProductionMode());
            this.trackers[TrackerName.ADLOCATE]?.init();
        } catch (e) {
            this.logger.log(`${TrackerName.ADLOCATE} initialization failed`);
        }
    }

    initializePertoPlugin(): void {
        const config = this.globalSettingService.get("analytics.perto");
        if (isTrackerRestrictedByGeolocation(config?.geolocationRestriction, this.getGeolocation())) {
            return this.logger.log(`${TrackerName.PERTO} is restricted`);
        }

        if (!config.enabled) {
            return this.logger.log(`${TrackerName.PERTO} is disabled.`);
        }

        try {
            this.trackers[TrackerName.PERTO] = new PertoTracker(config, this.isProductionMode());
            this.trackers[TrackerName.PERTO]?.init();
        } catch (e) {
            this.logger.log(`${TrackerName.PERTO} initialization failed`);
        }
    }

    private initializeYahoo(): void {
        const config = this.globalSettingService.get("analytics.yahoo");
        if (!isTrackerInAllowedGeolocation(config.enabled, config?.geolocationAllowed, this.getGeolocation())) {
            return this.logger.log(`${TrackerName.YAHOO}  is disabled`);
        }

        try {
            this.trackers[TrackerName.YAHOO] = new YahooTracker(config, this.isProductionMode());
            this.trackers[TrackerName.YAHOO]?.init();
        } catch (e) {
            this.logger.log(`${TrackerName.YAHOO} initialization failed`);
        }
    }

    private initializeLine(): void {
        const config = this.globalSettingService.get("analytics.line");
        if (!isTrackerInAllowedGeolocation(config.enabled, config?.geolocationAllowed, this.getGeolocation())) {
            return this.logger.log(`${TrackerName.LINE}  is disabled`);
        }

        try {
            this.trackers[TrackerName.LINE] = new LineTracker(config, this.isProductionMode());
            this.trackers[TrackerName.LINE]?.init();
        } catch (e) {
            this.logger.log(`${TrackerName.LINE} initialization failed`);
        }
    }

    private initializeCriteo(): void {
        const config = this.globalSettingService.get("analytics.criteo");
        if (!isTrackerInAllowedGeolocation(config.enabled, config?.geolocationAllowed, this.getGeolocation())) {
            return this.logger.log(`${TrackerName.CRITEO}  is disabled`);
        }

        try {
            this.trackers[TrackerName.CRITEO] = new CriteoTracker(config, this.isProductionMode());
            this.trackers[TrackerName.CRITEO]?.init();
        } catch (e) {
            this.logger.log(`${TrackerName.CRITEO} initialization failed`);
        }
    }

    private initializeClarity(): void {
        const config = this.globalSettingService.get("analytics.clarity");
        if (!config.enabled) {
            return this.logger.log(`${TrackerName.CLARITY} is disabled`);
        }

        try {
            this.trackers[TrackerName.CLARITY] = new ClarityTracker(config, this.isProductionMode());
            this.trackers[TrackerName.CLARITY]?.init();
        } catch (e) {
            this.logger.log(`${TrackerName.CLARITY} initialization failed`);
        }
    }

    static getEventStoreKey(trackerName: string, eventParams: { [key: string]: any }): string {
        const serializeParams = (params) => {
            return reduce(keys(params), (acc, key) => {
                let value = isObject(params[key]) ? serializeParams(params[key]) : params[key];
                if (isArray(value)) {
                    value = join(value, "_");
                }
                return `${acc}_${key}_${value}`;
            }, "");
        };
        return `${trackerName}${serializeParams(eventParams)}`;
    }

    getTrackerByName(trackerName: string): Tracker | undefined {
        return this.trackers[trackerName];
    }

    isProductionMode(): boolean {
        return this.globalSettingService.get("productionMode");
    }

    updateCustomDimensions(trackerName: TrackerName, customDimensions: TrackerCustomDimensions): void {
        this.zone.runOutsideAngular(() => {
            if (!this.isGaTrackerName(trackerName)) {
                this.logger.error(`${LOG_TAG_ANALYTICS} Custom dimensions are not set since they are only available for GA at this time...`);
                return;
            }
            const tracker = this.getTrackerByName(trackerName);
            if (!isFunction(tracker?.updateCustomDimensions)) {
                this.logger.error(`${LOG_TAG_ANALYTICS} Custom dimensions are not set since given tracker does not have a method called 'updateCustomDimensions'...`);
                return;
            }
            const nextDimensions = tracker.updateCustomDimensions(customDimensions);
            this.logger.log(`${LOG_TAG_ANALYTICS} Updating custom dimensions...`, nextDimensions);
        });
    }

    trackEvent(
        trackerName: TrackerName,
        eventParams?: AnalyticsFacebook.TrackParams | AnalyticsGoogle.TrackParams | AnalyticsTiktok.TrackParams
    ): void {
        if (!this.trackers[trackerName]) {
            return;
        }
        this.zone.runOutsideAngular(() => this.trackers[trackerName]?.trackEvent(eventParams));
    }

    isGaTrackerName(trackerName: string): boolean {
        return trackerName == TrackerName.GA;
    }

    trackEventOnce(
        trackerName: TrackerName,
        eventParams: AnalyticsFacebook.TrackParams | AnalyticsGoogle.TrackParams
    ): void {
        this.zone.runOutsideAngular(() => {
            const eventStoreKey = AnalyticsService.getEventStoreKey(trackerName, eventParams);
            if (this.eventStore[eventStoreKey]) {
                return;
            }
            this.eventStore[eventStoreKey] = eventStoreKey;
            this.trackEvent(trackerName, eventParams);
        });
    }

    trackPageView(page?: string): void {
        map(keys(this.trackers), (trackerKey) => {
            this.trackers[trackerKey]?.trackPageView(page);
        });
    }

    trackTiming(timingCommandMethodFields: AnalyticsGoogle.TimingCommandMethodFields): void {
        // @FIXME too tightly coupled with GA
        this.trackers[TrackerName.GA]?.trackTiming(timingCommandMethodFields);
    }

    trackConversion(trackerName: TrackerName, conversionParams?: TrackConversionParams): void {
        if (!this.trackers[trackerName]) {
            return;
        }

        if (this.isGaTrackerName(trackerName)) {
            this.zone.runOutsideAngular(() => {
                this.gaAdsTracker?.trackConversion(conversionParams);
            });
            return;
        }

        this.trackers[trackerName]?.trackConversion(conversionParams);
    }

    private setGeolocation(geolocation: string): void {
        this.geolocation = geolocation;
    }

    private getGeolocation(): string {
        return this.geolocation;
    }
}
