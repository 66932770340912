import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { AjaxResponse } from "rxjs/ajax";
import { share } from "rxjs/operators";
import { ConnectionFactoryService } from "../../core/connection-factory.service";
import { GlobalObservableCache } from "../../core/global-observable-cache";
import { MemoryCache } from "../../core/memory-cache";
import { StorageCache } from "../../core/storage-cache";
import { AccountSubscription, AccountSubscriptionStatus } from "../types/account-subscription";
import { AccountTrackMatch } from "../types/account-track-match";
import { Identity, PartnerName, PartnerNames } from "../types/identity";
import { AccountProperties } from "../types/account-properties";
import { assign, keys } from "lodash-es";
import { AccountDemographics } from "../types/account-demographics";
import { AccountIdentity } from "../../core/identity.service";
import { LdapUsers } from "../types/ldap-users";
import { Logger } from "../../core/logger/logger";

interface AccountPasswordFormData {
    oldPassword?: string;
    newPassword: string;
    facebookID?: number;
    cmsUserID?: number;
    googleID?: string;
}

interface AccountSearchParams {
    accountIDs?: number | number[] | string;
    fields: string | string[];
    isServer: boolean;
}

@Injectable({
    providedIn: "root"
})
export class AccountModelService {

    // *** Partner name cache
    private partnerNamesCache = new MemoryCache<PartnerNames>();
    private accountTrackCache = new MemoryCache<number>();
    private demographicIdsListCache = new StorageCache<AccountDemographics[]>("AccountModelService");
    private accountMemoryCache: MemoryCache<Identity> = new MemoryCache<Identity>();
    private accountListCache = new StorageCache<Identity[]>("AccountList");
    private accountSearchIdsListCache = new StorageCache<number[]>("AccountSearch");
    private accountPropertiesCache = new StorageCache<AccountProperties[]>("AccountProperties");
    private accountDemographicCache = new StorageCache<AccountDemographics>("AccountDemographics");
    private accountDemographicListCache = new StorageCache<AccountDemographics>("AccountDemographicsList");

    private logger = new Logger();

    constructor(private connection: ConnectionFactoryService) {
    }

    getIdentityByAccountId(accountId: number, additionalOptions?: object): Observable<any> {
        return this.connection
            .service("bridge")
            .setPath("/identity/account/" + accountId)
            .get(additionalOptions);
    }

    getIdentityCacheByAccountId(accountId: number,
                                additionalOptions?: object,
                                refresh: boolean = false,
                                expiration: number = ConnectionFactoryService.CACHE_LIFETIME.identity): Observable<any> {
        let cacheKey = assign({
            key: "getIdentityCacheByAccountId",
            accountId: accountId
        }, additionalOptions);
        return this.accountListCache.getCache(cacheKey, () => {
            return this.getIdentityByAccountId(accountId, additionalOptions);
        }, expiration, refresh);
    }

    getIdentityMemoryCacheByAccountId(accountId: number,
                                      additionalOptions?: object): Observable<any> {
        let cacheKey = assign({
            key: "getIdentityMemoryCacheByAccountId",
            accountId: accountId
        }, additionalOptions);
        return this.accountMemoryCache.getCache(cacheKey, () => {
            return this.getIdentityByAccountId(accountId, additionalOptions);
        });
    }

    getIdentityAccounts(params?: object): Observable<any> {
        return this.connection
            .service("bridge")
            .setPath("/identity/account")
            .get(params);
    }

    getIdentityById(identityId: string): Observable<any> {
        return this.connection
            .service("bridge")
            .setPath("/identity/identity/" + identityId)
            .get();
    }

    getDeviceIdentityId(): Observable<string> {
        return GlobalObservableCache.getCache("getDeviceIdentityId", () => {
            return this.connection
                .service("site")
                .setPath("/identity/authenticate")
                .get({method: "getDeviceIdentityId"})
                .pipe(share());
        });
    }

    getIdentityByAccountIds(params?: Partial<AccountSearchParams>): Observable<Identity[]> {
        if (!params?.accountIDs) { //never query services if no accountIDs are passed
            return of([]);
        }
        return this.connection
            .service("bridge")
            .setPath("/identity/account/")
            .get(params);
    }

    getIdentityForClassReports(params?: object): Observable<Identity[]> {
        let defaultParams = {
            fields: "email,subscription,dateAdded,goLiveEligible,hasUsedMobile,isCourseOnly"
        };

        params = assign(defaultParams, params);

        return this.connection
            .service("bridge")
            .setPath("/identity/account/class/report/details")
            .get(params);
    }

    getIdentityListByAccountIds(params: object,
                                refresh: boolean = false,
                                expiration: number = ConnectionFactoryService.CACHE_LIFETIME.identity): Observable<Identity[]> {
        let cacheKey = assign({
            key: "getIdentityListByAccountIds"
        }, params);
        return this.accountListCache.getCache(cacheKey, () => {
            return this.getIdentityByAccountIds(params);
        }, expiration, refresh);
    }

    getLatestAuthentication(accountId: number): Observable<any> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/authentication/latest/account/${accountId}`)
            .get();
    }

    getAccountProperties(param: object): Observable<AccountProperties[]> {
        return this.accountPropertiesCache.getCache(param, () => {
            return this.getAccountPropertiesRaw(param);
        });
    }

    getAccountPropertiesRaw(param: object): Observable<AccountProperties[]> {
        return this.connection
            .service("bridge")
            .setPath("/identity/account/properties")
            .get(param);
    }

    // @INFO: Page starts with 1 for ldap users
    getLdapAccounts(page = 1): Observable<LdapUsers> {
        return this.connection
            .service()
            .setPath("/user/list")
            .get({
                page,
                params: encodeURIComponent("{\"Enabled\":1}"),
                AJAX_REQUEST: 1
            });
    }

    getUserLanguage(accountId: number): Observable<string> {
        if (!accountId) {
            this.logger.log("accountId is required param");
            return of("");
        }
        return this.connection
            .service("bridge")
            .setPath(`/identity/account/${accountId}/user/language`)
            .get();
    }

    searchAccounts(searchText: string, page = 0): Observable<{ list: Partial<Identity>[], count: number }> {
        return this.connection
            .service()
            .setPath("/account/search-account")
            .get({
                searchText,
                page,
                AJAX_REQUEST: 1
            });
    }

    updateAccountProperties(accountProperties: object): Observable<any> {
        this.accountPropertiesCache.destroy();
        return this.connection
            .service("bridge")
            .setPath("/identity/account/properties")
            .put(accountProperties);
    }

    updateAccount(param: object, accountId: number): Observable<Identity> {
        return this.connection
            .service("bridge")
            .setPath("/identity/account/" + accountId)
            .put(param);
    }

    updateAccountPassword(
        formData: AccountPasswordFormData
    ): Observable<AjaxResponse<void>> {
        const ajaxSettings = {
            method: "POST",
            headers: {
                "Content-Type": "application/vnd.englishcentral-v1+json"
            },
            body: JSON.stringify(formData),
            crossDomain: false
        };
        return this.connection
            .create()
            .setPath("/api/bridge/identity/account/password")
            .ajax(ajaxSettings);
    }

    updateChildAccountPassword(accountId: number, newPassword: string): Observable<void> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/account/changePassword/${accountId}`)
            .post({}, {
                cmsUserID: accountId,
                newPassword: newPassword
            });
    }

    countCmsPasswordChanges(accountId: number): Observable<number> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/account/countCMSPasswordChanges/${accountId}`)
            .get();
    }

    isCmsPasswordChanged(accountId: number): Observable<boolean> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/account/isPasswordChanged/${accountId}`)
            .get();
    }

    revertCmsPassword(accountId: number): Observable<void> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/account/revertPassword/${accountId}`)
            .post();
    }

    uploadLogo(formData: FormData): Observable<AjaxResponse<void>> {
        let ajaxSettings = {
            method: "POST",
            headers: {
                "Cache-Control": "no-store",
                "Pragma": "no-cache"
            },
            body: formData,
            crossDomain: false
        };

        return this.connection
            .create()
            .setPath("/api/bridge/identity/account/avatar")
            .ajax(ajaxSettings);
    }

    removeLogo(formData: FormData): Observable<AjaxResponse<void>> {
        let ajaxSettings = {
            method: "DELETE",
            headers: {
                "Cache-Control": "no-store",
                "Pragma": "no-cache"
            },
            body: formData,
            crossDomain: false
        };

        return this.connection
            .create()
            .setPath("/api/bridge/identity/account/avatar")
            .ajax(ajaxSettings);
    }

    deleteAccount(accountId: number, params: { [key: string]: any }): Observable<void> {
        return this.connection
            .service("bridge")
            .setPath("/identity/account/" + accountId)
            .delete(params);
    }

    getAccountTrack(accountId: number, params?: object): Observable<number> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/account/${accountId}/track`)
            .get(params);
    }

    getAccountTrackCache(accountId: number, params?: object): Observable<number> {
        return this.accountTrackCache.getCache({accountIds: accountId}, () => {
            return this.getAccountTrack(accountId, params);
        });
    }

    getAccountTracks(
        params: { accountIDs: string, endModified?: string, page?: number, pageSize?: number, startModified?: string }
    ): Observable<AccountTrackMatch[]> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/account/tracks/search`)
            .get(params);
    }

    postAccountTrack(accountId: number, params: { trackID: number, active?: boolean }): Observable<number> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/account/${accountId}/track`)
            .post(params);
    }

    getDemographicIds(accountId: number, demographicTypeName: string): Observable<AccountDemographics[]> {
        return this.connection
            .service("bridge")
            .setPath("/identity/account/demographics/type/" + demographicTypeName)
            .get({});
    }

    getDemographicIdsListCache(accountId: number,
                               demographicTypeName: string,
                               refresh: boolean = false,
                               expiration: number = ConnectionFactoryService.CACHE_LIFETIME.identity): Observable<AccountDemographics[]> {
        let cacheKey = {
            accountId: accountId,
            demographicTypeName: demographicTypeName
        };
        return this.demographicIdsListCache.getCache(cacheKey, () => {
            return this.getDemographicIds(accountId, demographicTypeName);
        }, expiration, refresh);
    }

    getRawAccountDemographicsById(accountId: number): Observable<AccountDemographics> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/account/demographics/${accountId}`)
            .get({});
    }

    getAccountDemographicsById(accountId: number,
                               refresh: boolean = false,
                               expiration: number = ConnectionFactoryService.CACHE_LIFETIME.identity): Observable<AccountDemographics> {
        const cacheKey = {key: "getAccountDemographics", accountId};
        return this.accountDemographicCache.getCache(cacheKey, () => {
            return this.getRawAccountDemographicsById(accountId);
        }, expiration, refresh);
    }

    getRawAccountDemographics(): Observable<AccountDemographics> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/account/demographics`)
            .get({});
    }

    getAccountDemographics(refresh: boolean = false,
                           expiration: number = ConnectionFactoryService.CACHE_LIFETIME.identity): Observable<AccountDemographics> {
        const cacheKey = {key: "getAccountDemographics"};
        return this.accountDemographicListCache.getCache(cacheKey, () => {
            return this.getRawAccountDemographics();
        }, expiration, refresh);
    }

    updateDemographics(accountId: number, param: object): Observable<AccountDemographics> {
        return this.connection
            .service("bridge")
            .setPath("/identity/account/demographics/account/" + accountId)
            .put({}, param, ConnectionFactoryService.SERVICE_VERSION.v2, undefined, {
                "Content-Type": "application/x-www-form-urlencoded"
            });
    }

    deleteAccountDemographic(accountDemographicId: number, params: object): Observable<void> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/account/demographics/accountdemographics/${accountDemographicId}`)
            .delete(params);
    }

    postStudentNumber(organizationId: number, accountId: number, studentNumber: number): Observable<any> {
        return this.connection
            .service("bridge")
            .setPath(`/identity/organization/${organizationId}/studentdata/${accountId}`)
            .post({organizationStudentID: studentNumber});
    }

    refreshIdentity(): Observable<any> {
        return this.connection
            .service("site")
            .setPath("/identity/authenticate")
            .post({}, {"method": "refreshIdentity"}, "", {});
    }

    getIdentity(accountId?: number, identityId?: string): Observable<Identity> {
        if (accountId) {
            return this.getIdentityByAccountId(accountId, {
                fields: keys(new Identity()).join(",")
            });
        }

        if (identityId) {
            return this.getIdentityById(identityId);
        }

        return throwError("AccountID or IdentityID is required");
    }

    getPartnerUsername(): Observable<PartnerName> {
        return GlobalObservableCache.getCache("getPartnerUsername", () => {
            return this.connection
                .service("site")
                .setPath("/identity/authenticate")
                .post({}, {"method": "getpartnerusername"}, "", {})
                .pipe(share());
        });
    }

    getRawPartnerUsernames(accountIds: string): Observable<PartnerNames> {
        return this.connection
            .service("site")
            .setPath("/identity/authenticate")
            .post({}, {"method": "getpartnerusernames", "accountIds": accountIds}, "", {});
    }

    getPartnerUsernames(accountIds: string): Observable<PartnerNames> {
        return this.partnerNamesCache.getCache({accountIds: accountIds}, () => {
            return this.getRawPartnerUsernames(accountIds);
        });
    }

    getSearchIds(params: object): Observable<number[]> {
        return this.connection
            .service("bridge")
            .setPath("/identity/account/search/ids")
            .get(params);
    }

    getSearchIdsListCache(params: object,
                          refresh: boolean = false,
                          expiration: number = ConnectionFactoryService.CACHE_LIFETIME.identity): Observable<number[]> {
        let cacheKey = assign({
            key: "getSearchIdsListCache"
        }, params);
        return this.accountSearchIdsListCache.getCache(cacheKey, () => {
            return this.getSearchIds(params);
        }, expiration, refresh);
    }

    getAccountLastVisit(params: object): Observable<AccountIdentity[]> {
        return this.connection
            .service("bridge")
            .setPath("/identity/account/lastVisit")
            .get(params);
    }

    getAccountSubscriptionStatus(accountId: number): Observable<AccountSubscriptionStatus[]> {
        return this.connection
            .service("bridge")
            .setPath("/commerce/subscription/status/account/" + accountId)
            .get();
    }

    upgradeAccountSubscription(accountId: string, params: object = {}): Observable<AccountSubscription[]> {
        return this.connection
            .service("bridge")
            .setPath("/commerce/subscription/account/" + accountId)
            .post(params);
    }

    getAccountSubscription(accountId: string, params: object = {}): Observable<AccountSubscription[]> {
        return this.connection
            .service("bridge")
            .setPath("/commerce/subscription/account/" + accountId)
            .get(params);
    }

    downgradeAccountSubscription(accountId: string, productId: number): Observable<AccountSubscription[]> {
        return this.connection
            .service("bridge")
            .setPath("/commerce/subscription/account/" + accountId + "/product/" + productId)
            .delete();
    }

    getAsset(params: { accountIDs?: number[], assetTypeIDs?: number[], cdnType?: string}): Observable<any> {
        if (!params.accountIDs || !params.assetTypeIDs) {
            return of(undefined);
        }
        return this.connection
            .service("bridge")
            .setPath("/identity/account/asset")
            .get(params);
    }

    setRole(accountId: number, roleTypeId: number, set: boolean): Observable<void> {
        if (!accountId || !roleTypeId) {
            return of(undefined);
        }
        return this.connection
            .service("bridge")
            .setPath(`/identity/account/${accountId}/roletype/${roleTypeId}`)
            .post({ value: set });
    }

    postAsset(params: { accountID: number, assetTypeID: number, assetURL: string, description?: string }): Observable<void> {
        if (!params.accountID) {
            return;
        }
        return this.connection
            .service("bridge")
            .setPath("/identity/account/asset")
            .post(params);
    }

    deleteAccountCache(): void {
        this.accountMemoryCache.destroy();
    }

    deleteAccountListCache(): void {
        this.accountListCache.destroy();
    }

    deleteAccountDemographicsMemory(): void {
        this.accountDemographicListCache.destroy();
    }

    deleteAccountDemographicCache(): void {
        this.accountDemographicCache.destroy();
    }

    deleteAccountTrackCache(): void {
        this.accountTrackCache.destroy();
    }
}


