import { TrackerLogger } from "../tracker.logger";
import { Tracker, TrackerName } from "../tracker";


export class PertoTracker extends TrackerLogger implements Tracker {

    constructor(private config: any,
                private isProductionMode: boolean = false) {
        super(TrackerName.PERTO, isProductionMode);
    }

    isEnabled(): boolean {
        return this.config.enabled;
    }

    init(): void {
        this.checkPertoPlugin();
    }

    /* eslint-disable */
    private loadPerto(e, o, n) {
        return new Promise((t, c) => {
            const r = document.createElement("script");
            function i() {
                if ((document.head.removeChild(r), n)) {
                    const e = document.createElement("script");
                    (e.src = n),
                        document.head.appendChild(e),
                        (e.onload = () => {
                            t(!0);
                        }),
                        (e.onerror = () => {
                            t(!1);
                        });
                } else t(!1);
            }
            (r.src = e), document.head.appendChild(r);
            const u = setTimeout(() => {
                i();
            }, o);
            (r.onload = () => {
                clearTimeout(u), t(!0);
            }),
                (r.onerror = () => {
                    clearTimeout(u), i();
                });
        });
    }

    private checkPertoPlugin() {
        "complete" === document.readyState
            ? window.setTimeout(() => {
                this.loadPerto("https://cdn.pertoplugin.link/plugin/perto.js.gz", 5e3, "https://pertocdn.pertoplugin.link/plugin/perto.js.gz")
                    .then(e => {
                        console.log("Script carregado: ", e);
                    })
                    .catch(e => {
                        console.error("Erro ao carregar o script: ", e);
                    });
            }, 2e3)
            : window.setTimeout(() => {
                this.checkPertoPlugin();
            }, 1e3);
    }
    /* eslint-enable */

    trackPageView(): void {
    }

    trackEvent(): void {
    }
}
