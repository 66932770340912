import {
    AfterViewInit,
    Component,
    ComponentRef,
    EventEmitter,
    OnDestroy,
    Output,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import { LazyLoaderService } from "../../../core/lazy-loader.service";
import { Logger } from "../../../core/logger/logger";

@Component({
    selector: "ec-freelesson-promo-app-modal",
    templateUrl: "freelesson-promo-app-modal.component.html",
    standalone: false
})
export class FreelessonPromoAppModalComponent implements AfterViewInit, OnDestroy {
    @ViewChild("vcRef", {read: ViewContainerRef}) vcRef: ViewContainerRef;
    @Output() eventClose: EventEmitter<any> = new EventEmitter<any>();
    private componentRef: ComponentRef<any>;
    private logger = new Logger();

    constructor(private lazyLoaderService: LazyLoaderService) {
    }

    ngAfterViewInit() {
        this.loadLazyModule().then(() => {
            this.logger.log("FL promo module loaded...");
        });
    }

    async loadLazyModule(): Promise<any> {
        const { FreelessonPromoAppModule } = await import("./freelesson-promo-app.module");
        const { FreelessonPromoAppComponent } = await import("./freelesson-promo-app.component");
        this.lazyLoaderService.load(
            this.vcRef,
            FreelessonPromoAppComponent,
            FreelessonPromoAppModule
        )
            .then((componentRef) => {
                this.componentRef = componentRef;
                this.componentRef.instance.eventClose.subscribe((closedWithManualRedirection) => {
                    this.eventClose.emit(closedWithManualRedirection);
                });
            });
    }

    ngOnDestroy() {
        this.componentRef.destroy();
    }
}
